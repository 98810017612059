.footer-container{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #eee;
        box-shadow: 3px 3px 3px 3px #bbb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #444;
        
}

.footer-right-side{
    display:"flex";
    flex-direction: row;
    justify-content: flex-end;

}

.footer-text{
    color: #444;
    font-size: 12px;
    margin-right: 20px;
}


.footer-left-text{
    font-size: 12px;
    color: #444;
    margin-left: 20px;
}

:hover.footer-text{
    color: #ff5a00;
}

:hover.footer-left-text{
    color: #ff5a00;
}
