.SWBPublicLocationDropdownSearch {
  position: absolute;
  z-index: 9999999999;
  margin-left: 5%;
  margin-top: 25px;
  width: 90%;
  .ui.icon.input input {
    border-radius: 100px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.ui.input.SWBPublicLocationDropdownSearch input {
  border-radius: 100px;
}

#root
  > div
  > div.ui.fluid.container.screen-wrapper.screen-wrapper--new-layout
  > div.main-content
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  > div.sixteen.wide.column
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div {
  width: 100%;
}
