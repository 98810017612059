@import "./../../assets/styles/colors.scss";

.Toastify {
  * {
    color: $primaryColor2;
  }
  &__progress-bar {
    background: $primaryColor;
  }
  &__toast {
    background: $lightGrey;
    border-radius: 10px;
    .icon {
      span {
        color: green;
        font-size: 40px;
      }
    }
    div.right {
    }
    &.info {
    }
  }
}
