@import "./../../../assets/styles/colors.scss";
.table--virtual {
  //display: inline-block;
  //border-spacing: 0;
  //border: 1px solid black;
  height: 100%;
  width: 100%;
  position: relative;
  .table-header {
    //background: red;
    .tr {
      padding: 5px 0;
      //border-bottom: 1px solid #ddd;
      position: relative;
      width: 100% !important;
      &.tr--filter {
        display: flex;
        .th {
          flex: 1;

          &--flex {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            &.center {
              justify-content: flex-start;
            }
            & > * {
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
      &:last-child {
        //border: solid 2px #666;
        .th:first-child {
          border-radius: 15px 0 0 15px;
          border-left: 1px solid $borderColor;
          border-bottom: solid 1px $borderColor;
          border-top: solid 1px $borderColor;
          padding-left: 1.2em;
        }
        .th:last-child {
          border-radius: 0 15px 15px 0;
          border-right: 1px solid $borderColor;
          border-bottom: solid 1px $borderColor;
          border-top: solid 1px $borderColor;
          text-align: right;
          padding-right: 1.2em;
        }
        .th {
          border-bottom: solid 1px $borderColor;
          border-top: solid 1px $borderColor;
          padding: 0.2em;
        }
      }
    }
  }
  .tbody {
    width: 100%;
    height: calc(100% - 110px);
    .tr {
      margin-top: 1px;
      &:hover {
        .td {
          background: $lightGrey;
          vertical-align: middle
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      .td {
        height: 100%;
        padding: 0 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .td:first-child {
        border-radius: 15px 0 0 15px;
      }
      .td:last-child {
        border-radius: 0 15px 15px 0;
      }
      &.selected {
        .td {
          background: $primaryColor;
          color: $white;
          a {
            color: $white;
          }
        }
        .label.badge {
          color: $primaryColor;
          background: $white;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      border-bottom: 1px solid $borderColor;
      //border-right: 1px solid black;

      :last-child {
      }
    }
  }
}

.hide-columns {
  .list {
    .column.item {
      width: 100%;
      max-width: 300px;
      padding: 4px 5px;
      label {
        input {
          margin-right: 10px;
        }
      }
    }
  }
}
table.ui.table {
  tbody {
    width:100%;
    tr.table-row {
      position: relative;
      td:last-child {
        text-align: center;
        border-radius: 0 5px 5px 0;
      }
      td:first-child {
        border-radius: 5px 0 0 5px;
      }
      &.exception {
        background: rgba(200, 0, 0, 0.4);
      }
      &.selected {
        background: $primaryColor;
        * {
          color: white;
        }
        i {
          color: white !important;
        }
        a.ui.button.icon {
          * {
            color: #000 !important;
          }
        }
      }
      .info-panel {
        position: absolute;
        right: 0;
        top: 0;
        background: white;
      }
    }
  }
}
.outer-table {
  position: relative;
  overflow-y: hidden;
}
table.ui.table.sticky,
table.ui.table--sticky {
  //overflow: hidden;
  height: 100%;
  position: relative;
  thead tr:first-child th {
    position: sticky !important;
    background: white;
    z-index: 9999 !important;
    top: 0px;
  }
  thead tr:nth-child(2) th {
    position: sticky !important;
    top: 60px;
    z-index: 9999;
    background: white !important;
  }
}
.table-header {
  &--row {
    display: flex;
    justify-content: space-between;
  }
}

.visible.menu.transition {
  margin-left: 65px;
  position: absolute !important;
  z-index: 1000;
}

.pagination {
  overflow: initial !important;
}
.ui.table.sharingPoints {
  thead {
    .filter-tr th .flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .ui.search {
      width: 100%;
      .ui.input {
        width: 80%;
      }
    }
  }
}
