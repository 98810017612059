/** @format */

@import "./colors.scss";
body {
  background-color: #f9f9fa;
  height: 100%;
}
.App {
  overflow: hidden;
  color: #fff;
  flex: 1;

  & > .fluid.container > .fluid.container {
    overflow-y: auto;
  }
}
.App * {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  box-sizing: border-box;

  //color: #222;
  //overflow: hidden;
}
fieldset {
  border: solid 2px #ccc;
}
fieldset legend {
  background: $primaryColor;
  padding: 5px 10px;
}

.ui.search {
  border-radius: 10px !important;
}

.ui.mini.icon.input input {
  border-radius: 10px !important;
}

.ui.table tbody tr td {
  background-color: #fff !important;
}

.ui.table tfoot tr th {
  background-color: #fff !important;
}

.ui.table tbody tr:hover {
  color: #ff5a00 !important;
  background: #ff5a00 !important;
  & a {
    color: #ff5a00 !important;
  }
}

.left-header {
  border: 1px solid #b9b9b9 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-right: none !important;
  background-color: white !important;
  font-weight: 500 !important;
}

.inner-header {
  border: 1px solid #b9b9b9 !important;
  border-right: none !important;
  border-left: none !important;
  background-color: white !important;
  font-weight: 500 !important;
}

.right-header {
  border: 1px solid #b9b9b9 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-left: 0px !important;
  background-color: white !important;
  font-weight: 500 !important;
}

.ui.table {
  border-radius: 0.5px !important;
  border: none !important;
}

.value-orange-text {
  color: #ff5a00 !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  pointer-events: none;
  opacity: 100;
}

/*.ui.primary.button {
  background-color: #ff5a00 !important;
}*/

/*.ui.large.active.inline.loader {
  color: !important;
}*/

// .ui.segment {
//   border-radius: 10px !important;
// }

@media screen and (max-height: 1080px) {
  .sixteen.wide.column.sp-simple-map {
    display: none;
  }
}
.ui.segment.ui.middle.aligned.one.column.grid.oauth2-sign-up-container {
  width: 35%;
}

@media screen and (max-width: 725px) {
  .main-header {
    display: none;
  }
  .screen-wrapper.screen-wrapper--new-layout {
    display: none;
  }
  .ui.segment.ui.middle.aligned.one.column.grid.oauth2-sign-up-container {
    width: 35% !important;
  }
  .ui.grid.profile-grid {
    width: 70% !important;
    margin: auto;
  }
}

@media screen and (min-width: 1440px) {
  .ui.grid.profile-grid {
    width: 80% !important;
    margin: auto;
  }
  .ui.container.batteries-index-screen {
    width: 99%;
  }
  .ui.container.batteries-show-screen {
    width: 99%;
  }
  .ui.one.column.grid.grid-swap-segment {
    width: 50%;
  }
  .ui.container.sharingpoints-table-view {
    width: 98%;
  }
  .ui.container.show-location-container {
    width: 75%;
  }
  .ui.container.user-management-container {
    width: 95%;
  }
  .ui.container.statistic-container {
    width: 95%;
  }
  .ui.segment.ui.middle.aligned.one.column.grid.oauth2-sign-up-container {
    width: 35% !important;
  }
}

@media screen and (min-width: 1920px) {
  .ui.container.batteries-index-screen {
    width: 70%;
  }
  .ui.container.batteries-show-screen {
    width: 70%;
  }
  .ui.one.column.grid.grid-swap-segment {
    width: 40%;
  }

  .ui.container.sharingpoints-table-view {
    width: 70% !important;
  }
  .ui.container.show-location-container {
    width: 70%;
  }

  .ui.container.user-management-container {
    width: 80%;
  }

  .ui.container.statistic-container {
    width: 95%;
  }
  .ui.segment.ui.middle.aligned.one.column.grid.oauth2-sign-up-container {
    width: 95% !important;
  }
}

.ui.active.visible.fluid.selection.upward.dropdown.visible.menu.transition {
  z-index: 9999999;
}

th {
  overflow: visible !important;
}

.ui.icon.button.burger-menu:hover {
  color: black !important;
}

.ui.large.active.inline.loader::before {
  border-color: #ff5a00;
}

.ui.bottom.attached.segment.active.tab {
  min-height: 600px;
}

/*.ui.label{
  background: #ff5a00;
  color: white
}*/

.form-control {
  margin-left: 20px;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
  color: #444;
  padding: 8px;
}
.dropdown-item {
  color: #444;
  padding: 8px;
}

.ui.tiny.primary.button {
  background-color: #ff5a00 !important;
}

.ui.primary.button {
  background-color: #ff5a00 !important;
}

.ui.mini.input input {
  border-radius: 10px;
}

.ui.fluid.selection.dropdown {
  border-radius: 10px;
}

.ui.mini.basic.icon.button {
  border-radius: 10px;
}

.ui.tiny.input input {
  border-radius: 10px;
}

.ui.left.icon.input input {
  border-radius: 10px;
}

.ui.fluid.input input {
  border-radius: 10px;
}

.swap-table-filter-container {
  display: flex;
  flex-wrap: wrap;
}

.filter-components {
  flex: 1 0 10%;
  margin-right: 5px;
}

.filter-components .field .ui.input {
  width: 100% !important;
  padding: 0px !important;
}

.ui.input input {
  border-radius: 10px;
}

.ui.selection.dropdown {
  border-radius: 10px;
}

.react-datepicker__input-container input {
  cursor: pointer;
  border-radius: 10px;
  height: 37px;
  border: solid #ddd 0.1px;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  width: 100%;
  padding: 10px;
}

.react-datepicker-ignore-onclickoutside {
  border: #444;
}

.leaflet-marker-icon.marker-cluster.marker-cluster-medium.leaflet-zoom-animated.leaflet-interactive
  div {
  background-color: #ff5a00;
  color: #fff;
}

.leaflet-marker-icon.marker-cluster.marker-cluster-small.leaflet-zoom-animated.leaflet-interactive
  div {
  background-color: #ff5a00;
  color: #fff;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
  width: 40px;
  right: 2px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.leaflet-control-zoom.leaflet-bar.leaflet-control a {
  width: 100%;
  color: #ff5a00;
}
a.leaflet-control-zoom-in {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.leaflet-control-zoom-out {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-popup-content-wrapper {
  background: #ff5a00;
  color: #f9f9fa;
}
