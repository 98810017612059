@import "./../../../assets/styles/colors.scss";

.icon.control.control--show-all {
  width: 40px;
  height: 40px;
  background: white;
  &:hover {
    box-shadow: 2px 2px 4px 2px rgba(0, 100, 50, 0.5);
  }
}
.leaflet-marker-icon {
  background: none;
  border: none;
}
.marker-cluster {
  color: black;
}

//style for leaflet markers
.leaflet-marker-pane {
  //z-index: 1999;
}
.ui.button.fly-to-button {
  z-index: 1;
  background: $white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  &:hover {
    background: #ccc;
  }
  svg {
    flex: 2;
    width: 40px;
    height: 40px;
  }
}
.leaflet-popup.sharingPoints {
  div.leaflet-popup-content-wrapper {
    position: relative;
    padding: 5px 5px 5px 10px;
    background: $primaryColor;
    min-height: 60px;
    margin-left: -5px;
    margin-top: 2px;
    .leaflet-popup-content {
      min-width: 160px;
      color: white;
      margin: 0 0 0 25px;
      max-width: 250px;
      .popup-content {
        .sp-name > h4 > a:hover {
          color: #aaa;
        }
        * {
          color: $lightGrey;
        }
        .row {
          display: flex;
          justify-content: space-between;
        }
        .image-wrapper {
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
          display: flex;
          align-items: center;
          .iconImg {
            width: 40px;
            height: 60px;
            margin-left: -19px;
            //margin-top: 12px;
          }
        }
        .footer {
          margin-top: 5px;
        }
      }
    }
  }
  div.leaflet-popup-tip-container {
    display: none;
  }
  a.leaflet-popup-close-button {
    display: none;
  }
}
/*
// for and from leaflet.marker-cluster.js
.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
	-webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
	-moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
	-o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly 
	-webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
	-moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
	-o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

	
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}
	*/
