@import "./colors.scss";

header.page-header {
  background: $primaryColor;
  padding: 5px 10px;
  
  .counter {
    margin-right: 10px;
  }
}

.spin-wrapper {
  height: 100px;
  margin: 50px;
  justify-content: center;
}

.row {
  &--centered {
    justify-content: center;
  }
  &--full {
    height: 100%;
  }
}
a.icon {
  color: black;
}
// .error {
//   &--message {
//     color: red;
//   }
// }
.input--highlight {
  .ui.input {
    border: orange 2px solid;
  }
}
